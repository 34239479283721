export default defineNuxtRouteMiddleware(async (to, from) => {
  // Check if we're on the client-side, as localStorage is not available on the server
  if (import.meta.client) {
    console.log("client side");
    const apiFetch = useSanctumClient();
    console.log("fetching connected users role and permissions");
    try {
      const rolesWithPermissions = await apiFetch("/api/users/rolesAndPermissions");
      const userPermissions = usePermissions()
      const userRoles = useRoles()
      let roles = new Set();
      let permissions = new Set();
      for (let i = 0; i < rolesWithPermissions.length; i++) {
        const roleWithPermissions = rolesWithPermissions[i];
        roles.add(roleWithPermissions.name);
        for (let j = 0; j < roleWithPermissions.permissions.length; j++) {
          permissions.add(roleWithPermissions.permissions[j].name);
        }
      }
      userPermissions.value = Array.from(permissions);
      userRoles.value = Array.from(roles);
    } catch (e) {
      console.error(e);
    }
  }
})
